<template>
  <el-dialog
    top="80px"
    width="600px"
    class="create-user-popup"
    title="Thêm tài khoản cho tổ chức"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top">
      <el-form-item label="Tên tổ chức" prop="summary">
        <el-input v-model="form.summary" readonly placeholder="Nhập tên tổ chức">
        </el-input>
      </el-form-item>
      <el-form-item label="Quyền" prop="staffType">
        <el-select v-model="form.role" class="w-100">
          <el-option
            v-for="(t, index) in roles"
            :key="index"
            :label="t.label"
            :value="t.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Loại tài khoản" prop="staffType">
        <el-select v-model="form.staffType" class="w-100">
          <el-option
            v-for="(t, index) in types"
            :key="index"
            :label="t.label"
            :value="t.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!owner" label="Tài khoản Owner" prop="users">
        <el-select
          v-model="form.ownerId"
          filterable
          :disabled="callingAPI"
          placeholder="Chọn tài khoản owner cho tổ chức"
          class="w-100"
          remote
          :remote-method="searchUsers"
          :loading="loading"
        >
          <el-option
            v-for="(user, index) in users"
            :key="index"
            :label="user.email"
            :value="user.id"
            :disabled="!!organization.users.find((u) => u.id === user.id)"
          >
            <el-row type="flex" align="middle">
              <el-avatar :size="24" :src="user.avatar_url">
              </el-avatar>
              <span class="ml-2">{{ user.name || user.email }}</span>
            </el-row>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-else label="Danh sách tài khoản" prop="users">
        <el-select
          v-model="form.users"
          multiple
          filterable
          :disabled="callingAPI"
          placeholder="Nhập thông tin tìm kiếm và Chọn tài khoản"
          class="w-100"
          remote
          :remote-method="searchUsers"
          :loading="loading"
        >
          <el-option
            v-for="(user, index) in users"
            :key="index"
            :label="user.email"
            :value="user.id"
            :disabled="!!organization.users.find((u) => u.id === user.id)"
          >
            <el-row type="flex" align="middle">
              <el-avatar :size="24" :src="user.avatar_url">
              </el-avatar>
              <span class="ml-2">{{ user.name || user.email }}</span>
            </el-row>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleAddMembers">Thêm</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  appointOwner,
  addMember,
  addAdmin
} from '@/services/organizations'
import { getUsers } from '@/services/user'
import { getUserRoles } from '@/utils/filters'

export default {
  name: 'AddUsers',
  props: {
    visible: Boolean,
    organization: Object,
    owner: Object
  },
  data() {
    return {
      loading: true,
      callingAPI: false,
      users: [],
      searchText: '',
      types: getUserRoles().filter((t) => ['user', 'moderator'].includes(t.value)),
      form: {
        summary: this.organization.summary,
        role: this.owner ? 'member' : 'owner',
        staffType: 'moderator',
        ownerId: null,
        users: []
      }
    }
  },
  computed: {
    roles() {
      if (!this.owner) {
        return [
          {
            value: 'owner',
            label: 'Owner'
          }
        ]
      }
      return [
        {
          value: 'member',
          label: 'Member'
        }
      ]
    },
    oldUserIds() {
      return this.organization.users.map((u) => u.id)
    },
    oldPlaceIds() {
      return this.organization.places.map((u) => u.id)
    },
    dataInput() {
      if (this.owner) {
        return (this.form.users || []).map((userId) => ({
          organization_id: this.organization.id,
          assigner_id: this.owner.user_id,
          assignee_id: userId
        }))
      }
      return {
        organization_id: this.organization.id,
        assignee_id: this.form.ownerId
      }
    },
    params() {
      if (this.form.staffType === 'moderator') {
        return {
          page: 1,
          per_page: 15,
          user_types: 'staff',
          search_text: this.searchText,
          staff_types: this.form.staffType
        }
      }
      return {
        page: 1,
        per_page: 15,
        user_types: 'user',
        search_text: this.searchText
      }
    }
  },
  watch: {
    'form.staffType': 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getUsers(this.params).then((response) => {
        this.users = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async handleAddMembers() {
      this.callingAPI = true
      const requests = []
      if (!this.owner) {
        requests.push(appointOwner(this.dataInput))
      } else if (this.form.role === 'admin') {
        this.dataInput.forEach(data => addAdmin(data))
      } else if (this.form.role === 'member') {
        this.dataInput.forEach(data => addMember(data))
      }
      try {
        await Promise.all(requests)
        this.$notify.success({
          title: 'Thành công',
          message: 'Thêm mới tài khoản thành công'
        })
        this.$refs.form.resetFields()
        this.callingAPI = false
        this.$emit('reload')
        this.$emit('close')
      } catch (error) {
        this.$notify.error({
          title: 'Thất bại',
          message: 'Không thể thêm tài khoản'
        })
        this.callingAPI = false
      }
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },

    searchUsers(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
